
import moment from "moment/moment";
import SaveButton from "../save-button";
import ContactCompanySelect from "./contact-company-select";
import ContactTypeSelect from "./contact-type-select";

export default {
    name: "ContinueContactModal",
    components: {ContactTypeSelect, ContactCompanySelect, SaveButton},
    props: {
        contact: {
            type: Object,
            required: true,
        },
        alreadyContinuedByAnotherContact: {
            type: Boolean,
            required: true,
        },
        alreadyContinuesAnotherContact: {
            type: Boolean,
            required: true,
        },
        fromContactList: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            uuid: this.generateUUID(),
            contactRelationshipType: "CONTINUED_BY",
            contactType: null,
            existingContact: null,
            company: null,
            jobTitle: null,
            contactTypeId: null,
            email: null,
            emailPersonal: null,
            usePersonalEmailForCommunication: false,
            phone: null,
            cell: null,
            addBiopharma: false,
            isLoading: false,
            dateConfig: {
                mode: 'range',
                altInput: true,
                altFormat: "m/d/Y",
                dateFormat: 'Y-m-d',
            },
            activeToType: "DEFAULT_ACTIVE_TO",
            activeTo: null
        };
    },
    computed: {
        continuesOtherContact() {
            return this.contactRelationshipType === 'CONTINUES';
        },
        createNewContact() {
            return this.contactType === 'NEW_CONTACT';
        },
        oldContactActiveTo() {
            if (this.contact.activeTo) {
                return this.formatDate(this.contact.activeTo);
            } else {
                return moment().subtract(1, 'days').format("MM/DD/YYYY");
            }
        },
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {

            if (this.alreadyContinuedByAnotherContact && !this.alreadyContinuesAnotherContact) {
                this.contactRelationshipType = 'CONTINUES';
            } else if (!this.alreadyContinuedByAnotherContact && this.alreadyContinuesAnotherContact) {
                this.contactRelationshipType = 'CONTINUED_BY';
            }

            // this.jobTitle = this.contact.jobTitle;
            // this.email = this.contact.email;
            this.emailPersonal = this.contact.emailPersonal;
            this.usePersonalEmailForCommunication = this.contact.usePersonalEmailForCommunication;
            // this.phone = this.contact.phoneFormatted;
            this.cell = this.contact.cellFormatted;
        });
    },
    methods: {
        continueContact() {
            this.isLoading = true;

            this.$axios.post(`/api/contacts/continue/${this.contact.id}`, {
                continuesOtherContact: this.continuesOtherContact,
                createNewContact: this.createNewContact,
                existingContactId: this.existingContact ? this.existingContact?.id : null,
                newCompany: this.company,
                newContactTypeId: this.contactTypeId,
                newJobTitle: this.jobTitle,
                newEmail: this.email,
                newEmailPersonal: this.emailPersonal,
                usePersonalEmailForCommunication: this.usePersonalEmailForCommunication,
                newPhone: this.phone,
                newCell: this.cell,
                addBiopharma: this.addBiopharma,
                activateTo: this.activeTo,
            }).then(response => {
                const toastId = this.generateUUID();

                this.addToast({
                    type: response.data.success ? 'success' : 'danger',
                    title: response.data.success ? 'Success' : 'Error',
                    message: response.data.message,
                    id: toastId,
                });

                this.$nextTick(() => {
                    this.toggleToast(toastId);

                    if (response.data.success) {
                        if(this.fromContactList) {
                            this.$emit('continued')
                        } else {
                            this.$router.push(`/contacts/${response.data.contactId}`);
                        }
                    }
                });

            }).finally(() => {
                this.isLoading = false;
            })
        },
    },
}
