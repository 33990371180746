
import iriPreparation from "../../mixins/iri-preparation";
import LoadingSpinner from "../loading-spinner.vue";
import SaveButton from "../save-button.vue";
import {VISIBILITY_OPTIONS} from "../../mixins/contract-visibilities";
import PdfViewer from "../pdf-viewer.vue";

export default {
    name: "ContractTemplateModal",
    components: {PdfViewer, SaveButton, LoadingSpinner},
    mixins: [iriPreparation],
    props: {
        id: {
            type: String,
            default: "addContractModal"
        },
        contractToEdit: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            loadingTemplateInfo: false,
            templates: [],
            selectedTemplate: null,
            templateInfo: null,
            signersByRole: {},
            submitting: false,
            visibility: null,
            VISIBILITY_OPTIONS,
            documentBase64: null,
            draft: true
        }
    },
    computed: {},
    watch: {
        selectedTemplate() {
            this.loadTemplateInfo();
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.loadTemplates();
        });
    },
    methods: {
        loadTemplates() {
            this.loading = true;
            this.$axios.get('/api/contracts/templates').then((response) => {
                this.templates = response.data;
            })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadTemplateInfo() {
            this.loadingTemplateInfo = true;
            this.templateInfo = null;
            this.signersByRole = {};
            this.$axios.get('/api/contracts/templates/info/' + this.selectedTemplate.value).then((response) => {
                this.templateInfo = response.data;
                this.documentBase64 = 'data:application/pdf;base64,' + this.templateInfo.documentData;
            })
                .finally(() => {
                    this.loadingTemplateInfo = false;
                });
        },
        submitForm() {
            if(this.draft) {
                if(!confirm("Are you sure you want to save this contract as a draft?")) {
                    return;
                }
            } else if(!confirm("Are you sure you want to send this contract?")) {
                return;
            }

            this.submitting = true;

            // Loop over signers by role
            const finalSignersByRole = {};
            for (const [role, contact] of Object.entries(this.signersByRole)) {
                finalSignersByRole[role] = contact.id;
            }

            this.$axios.post('/api/contracts/templates/send', {
                templateId: this.templateInfo.id,
                signersByRole: finalSignersByRole,
                visibility: this.visibility,
                draft: this.draft
            }).then(() => {
                let messageTitle = "Contract Sent";
                let messageText = "The contract has been sent successfully.";
                if(this.draft) {
                    messageTitle = "Draft Saved";
                    messageText = "The contract has been saved as a draft.";
                }

                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: messageTitle,
                    message: messageText,
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                this.$emit('contract-sent');
            })
                .finally(() => {
                    this.submitting = false;
                });
        }
    }
}
