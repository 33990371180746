

import modals from "../../mixins/modals";
import ContactName from "../contacts/contact-name.vue";
import QuintableSearchInput from "../helpers/quintable-search-input.vue";
import EditContractModal from "./edit-contract-modal.vue";
import RedraftContractModal from "./redraft-contract-modal.vue";
import ContractTemplateModal from "./contract-templates.vue";

export default {
    name: "ContractsList",
    components: {
        ContractTemplateModal,
        RedraftContractModal,
        QuintableSearchInput,
        ContactName,
        EditContractModal
    },
    mixins: [modals],
    props: {},
    data() {
        return {
            modal: null,
            tableConfig: {
                columns: [
                    {
                        headline: "Title",
                        sort: true,
                    }, {
                        headline: "Status",
                        breakpoint: "md",
                    }, {
                        headline: "Signers",
                        breakpoint: "md",
                    }, {
                        headline: "Owner",
                        breakpoint: "md",
                    }, {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                    }
                ],
                search: true,
                pagination: 50,
                ajaxUrl: '/api/contracts/list',
            },
            contractToEdit: null,
            tableUpdate: false,
            loadingCell: null,
            addContractLoading: false,
            selectedUsers: [],
            selectedStatuses: [],
            selectedContacts: [],
            filterOptions: null,
            contractIdToRedraft: null,
            // FIXME: filters might need to be updated
            contractTemplateModalKey: "contractTemplateModalKey",
        }
    },
    computed: {
        filters() {
            return {
                users: this.selectedUsers.map(u => u.id),
                statuses: this.selectedStatuses.map(s => s.value),
                contacts: this.selectedContacts.map(c => c.id),
            }
        }
    },
    watch: {},
    mounted() {

    },
    methods: {
        updateTable() {
            this.tableUpdate = !this.tableUpdate;
        },
        editContract(id) {
            this.contractToEdit = null;
            this.loadingCell = id;
            this.$axios.get(`/api/contracts/${id}`)
                .then(async (response) => {
                    await this.openModalWithContract(response.data);
                })
                .finally(() => {
                    this.loadingCell = null;
                });
        },
        deleteContract(id) {
            if (!confirm('Are you sure you want to delete this contract?')) {
                return;
            }
            this.loadingCell = id;
            this.$axios.delete(`/api/contracts/${id}`)
                .then((response) => {
                    this.updateTable();
                })
                .finally(() => {
                    this.updateTable();
                    this.loadingCell = null;
                });
        },
        addContract() {
            this.contractToEdit = null;
            this.addContractLoading = true;
            this.$axios.get('/api/contracts/template')
                .then(async (response) => {
                    await this.openModalWithContract(response.data);
                })
                .finally(() => {
                    this.addContractLoading = false;
                });
        },
        async openModalWithContract(contract) {
            this.contractToEdit = null;
            this.contractToEdit = contract;
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.editContractModal);
            await this.$nextTick();
            this.$refs.editContractModal.$el.addEventListener('hidden.bs.modal', this.handleModalClose);
        },
        handleModalClose() {
            this.$refs.editContractModal.$el.removeEventListener('hidden.bs.modal', this.handleModalClose);
            this.contractToEdit = null;
            this.modal = null;
            this.updateTable();
        },
        loadFilterOptions() {
            if (this.filterOptions) {
                return;
            }
            this.$axios.get('/api/contracts/filter_options')
                .then((response) => {
                    this.filterOptions = response.data;
                });
        },
        resetFilters() {
            this.selectedUsers = [];
            this.selectedStatuses = [];
            this.selectedContacts = [];
            // this.updateTable();
        },
        async redraftContract(id) {
            this.contractIdToRedraft = id;
            await this.$nextTick();
            console.log('opening redraft modal', this.$refs.redraftContractModal);
            this.modal = this.openModal(this.$refs.redraftContractModal);
        },
        redraftContractProceed(id, newTitle) {
            this.loadingCell = id;

            console.log('redrafting contract', id, newTitle);
            this.closeModal(this.modal);
            this.modal = null;


            this.$axios.post(`/api/contracts/${id}/redraft`, {newTitle})
                .then(async (response) => {
                    // this.updateTable();
                    await this.openModalWithContract(response.data);
                })
                .finally(() => {
                    this.loadingCell = null;
                    this.contractIdToRedraft = null;
                });
        },
        resend(id) {

            confirm('Are you sure you want to resend this contract?');
            this.loadingCell = id;
            this.$axios.post(`/api/contracts/${id}/resend`)
                .then((response) => {
                    if(response.data.status) {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Success",
                            message: response.data.message,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    } else {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "danger",
                            title: "Error",
                            message: response.data.message,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }
                    this.updateTable();
                }).finally(() => {
                    this.loadingCell = null;
                });
        },
        async openContractTemplateModal() {
            this.contractTemplateModalKey = this.generateUUID();
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.contractTemplateModal);
        },
        onContractSent() {
            this.closeModal(this.modal);
            this.modal = null;
            this.updateTable();
        }
    }
}
