
import moment from "moment";
import SaveButton from "../save-button";
import ContactEdit from "./contact-edit";
import iriPreparation from '~/mixins/iri-preparation'

export default {
    name: "EditContactModal",
    components: {SaveButton, ContactEdit},
    mixins:[iriPreparation],
    props: {
        contact: {
            type: Object,
            required: true
        },
        id: {
            type: String,
            default: "editDetailsModal"
        }
    },
    data() {
        return {
            loading: false,
            contactWorkingCopy: {},
            modalId: moment().unix()
        }
    },
    watch: {
        contact: {
            handler() {
                this.resetWorkingCopy();
            },
            immediate: true,
            deep: true,
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal',  ()  =>{
            this.modalId = moment().unix();
        })
    },
    methods: {
        resetWorkingCopy() {
            this.contactWorkingCopy = Object.assign({}, this.contact);
        },
        async submitForm() {
            this.loading = true;

            const finalContact = this.prepareIri(this.contactWorkingCopy);

            console.log("final",finalContact);

            finalContact.users = this.prepareIri(this.contactWorkingCopy.users);

            let createdCompany = null;
            if(finalContact.company && finalContact.company["search-select-created-option"]){
                const response = await this.$axios.post('/api/companies',finalContact.company);
                finalContact.company = response.data["@id"];
                createdCompany = response.data;
            }

            // force inactivePermanently to not be null
            if (finalContact.inactivePermanently === null) finalContact.inactivePermanently = false;

            this.$axios.patch('/api/contacts/' + this.contact.id, finalContact, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).then(() => {
                this.$emit("contact-updated");
                const toastIds = [];

                const toastIdUser = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Saved",
                    message: `Contact updated`,
                    id: toastIdUser,
                });

                toastIds.push(toastIdUser);

                if(createdCompany){

                    const toastIdCompany = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Saved",
                        message: `New company "${createdCompany.name}" was created, click here to view details`,
                        onClicked: () => {
                            this.$router.push('/companies/' + createdCompany.id);
                        },
                        id: toastIdCompany,
                    });
                    toastIds.push(toastIdCompany);
                }

                this.$nextTick(() => {
                    toastIds.forEach((toastId)=>{
                        this.toggleToast(toastId);
                    });
                })

            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
