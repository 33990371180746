import { render, staticRenderFns } from "./user-contact-group-card.vue?vue&type=template&id=2c0f1717&scoped=true"
import script from "./user-contact-group-card.vue?vue&type=script&lang=js"
export * from "./user-contact-group-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c0f1717",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableActions: require('/codebuild/output/src3271430876/src/web/components/table-actions.vue').default,ConfirmModal: require('/codebuild/output/src3271430876/src/web/components/confirm-modal.vue').default})
