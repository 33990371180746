
import SaveButton from "../save-button.vue";


export default {
    name: "RedraftContractModal",
    components: {SaveButton},
    props: {
        contractIdToRedraft: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            newTitle: "",
            loading: false,
        }
    },
    watch: {},
    mounted() {

    },
    methods: {
        submitForm() {
            this.loading = true;
            this.$emit("redraft-proceed",
                       this.contractIdToRedraft,
                       this.newTitle
            );
        }
    }
}
