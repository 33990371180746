import { render, staticRenderFns } from "./groups.vue?vue&type=template&id=99cf85e2"
import script from "./groups.vue?vue&type=script&lang=js"
export * from "./groups.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableFilters: require('/codebuild/output/src3271430876/src/web/components/table-filters.vue').default,FavStar: require('/codebuild/output/src3271430876/src/web/components/fav-star.vue').default,TableActions: require('/codebuild/output/src3271430876/src/web/components/table-actions.vue').default,ConfirmModal: require('/codebuild/output/src3271430876/src/web/components/confirm-modal.vue').default,HistoryModal: require('/codebuild/output/src3271430876/src/web/components/history-modal.vue').default,DownloadSelectColumnsModal: require('/codebuild/output/src3271430876/src/web/components/download-select-columns-modal.vue').default})
