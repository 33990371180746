
import moment from "moment";
import {mapActions} from "vuex";
import ConfirmModal from "../../components/confirm-modal";
import EditContactListModal from "../../components/emails/edit-contact-list-modal";
import HistoryModal from "../../components/history-modal";
import TableActions from "../../components/table-actions";
import modals from "../../mixins/modals";
import QuintableSearchInput from "../helpers/quintable-search-input.vue";
import DownloadSelectColumnsModal from "../download-select-columns-modal.vue";
import FavStar from "../fav-star.vue";
import TableFilters from "../table-filters.vue";
import ContactGroupBulkOperationModal from "./contact-group-bulk-operation-modal";
import ContactBulkOperationModal from "./contact-bulk-operation-modal";
import CloneContactGroupModal from "./clone-contact-group-modal"
import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "EmailGroupsComponent",
    components: {
        DownloadSelectColumnsModal,
        TableFilters,
        FavStar,
        QuintableSearchInput,
        ConfirmModal,
        EditContactListModal,
        HistoryModal,
        TableActions,
        ContactGroupBulkOperationModal,
        ContactBulkOperationModal,
        CloneContactGroupModal
    },
    mixins: [optionButtonTooltip, modals],
    props: {
        ownOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            emails: [],
            bulkUpdated: moment().unix(),
            contactBulkUpdated: moment().unix() + 1,
            loading: false,
            contactListToDelete: null,
            contactListsUpdated: moment().unix() + 2,
            contactListToEdit: null,
            contactListToClone: null,
            config: {
                ajaxUrl: '/api/emails/contact_lists',
                search: true,
                pagination: 25,
                columns: [
                    {
                        headline: "Name",
                        sort: true,
                    },
                    {
                        headline: "Contacts",
                        sort: true,
                    },
                    {
                        headline: "Temporary",
                        ignoreEmpty: true,
                    },
                    {
                        headline: "Author",
                        sort: true,
                        hidden: this.ownOnly,
                    },
                    {
                        headline: "Last Change",
                        sort: true,
                    },
                    {
                        headline: "Created At",
                        sort: true,
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                        ignoreEmpty: true,
                    },
                    {
                        headline: "Description",
                        breakpoint: "all",
                    },
                    {
                        headline: "Last Action",
                        breakpoint: "all",
                    },
                    {
                        headline: "Changed By",
                        breakpoint: "all",
                    },
                ],
                hideEmptyColumns: true,
                select: true,
                prettySelect: true,
                selectPosition: "pre",
            },
            editModal: null,
            cloneModal: null,
            contactListForHistoryId: null,
            isLoading: false,
            selectedAuthor: null,
            preSelectedRows: [],
            selectedRows: [],
            rows: [],
            bulkModal: null,
            contactBulkModal: null,
            bulkFilters: {},
            contactBulkFilters: null,
            selectedContactIds: null,
            downloadQuery: null,
            exportPath: "",
            selectedIdBackUp: [],
            preSelectedRowIds: [],
            pageChanged: false,
            ajaxRows: [],
            privateContactGroup: null,
            privateContactGroupOptions: [
                {
                    title: "Private",
                    value: true,
                },
                {
                    title: "Public",
                    value: false,
                }
            ],
        }
    },
    computed: {
        filters() {
            return {
                author: this.selectedAuthor ? this.selectedAuthor.id : null,
                privateContactGroup: this.privateContactGroup
            };
        },
    },
    watch: {
        filters() {
            this.pageChanged = true;
        },
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowIds.includes(rows[i].contactListId)) {
                        this.preSelectedRowIds.push(rows[i].contactListId);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].contactListId;

                    const index = this.preSelectedRowIds.indexOf(id);

                    if (!rows.map(r => r.contactListId).includes(id) && index !== -1) {
                        this.preSelectedRowIds.splice(index, 1);
                    }
                }
            }

            this.bulkFilters = {
                ids: this.preSelectedRowIds
            }
        },
        selectedContactIds() {
            this.contactBulkFilters = this.selectedContactIds
        },
        ownOnly: {
            handler(val) {
                if (val) {
                    this.selectedAuthor = {
                        id: this.userInfo.id,
                        name: this.userInfo.name
                    };
                }
            },
            immediate: true
        }
    },
    created() {
        this.setCurrentEmail(false);
    },
    mounted() {
        if (this.$route.query.contactListId && !this.ownOnly) {
            const contactListId = parseInt(this.$route.query.contactListId.toString());
            if (contactListId) {
                this.editContactList(contactListId);
            }
        }
    },
    methods: {
        ...mapActions({
            setCurrentEmail: "app/setCurrentEmail"
        }),
        sendEmail(id) {
            this.$router.push(`/emails/compose/group:${id}`)
        },
        openHistory(id) {
            this.contactListForHistoryId = id;
            this.$nextTick(() => {
                this.openModal(this.$refs.historyModal);
            });
        },
        onContactListUpdated() {
            this.contactListsUpdated = moment().unix()
        },
        addContactList() {
            this.contactListToEdit = null

            this.editModal = this.openModal(this.$refs.editContactListModal);
        },
        editContactList(id) {
            this.contactListToEdit = id;

            this.editModal = this.openModal(this.$refs.editContactListModal);
        },
        closeContactListModal() {
            this.closeModal(this.editModal);
        },
        cloneContactList(id) {
            this.contactListToClone = id;
            this.cloneModal = this.openModal(this.$refs.cloneContactGroupModal);
        },
        onCloneSubmitted() {
            this.contactListsUpdated = moment().unix();
            this.closeModal(this.cloneModal);
        },
        onRowsUpdated(data) {
            this.pageChanged = false;

            this.ajaxRows = data.rows;
            this.ajaxAll = data.all;

            this.preSelectedRows = JSON.parse(JSON.stringify(this.preSelectedRowIds)).map(id => {
                return {
                    key: "contactListId",
                    value: id
                }
            });
            setTimeout(() => this.initializeOptionButtonTooltips('rows updated'), 250);
        },
        deleteContactList(id) {
            this.contactListToDelete = id;
            this.openModal(this.$refs.deleteContactListModal);
        },
        downloadContactList(id) {
            this.exportPath = '/api/contacts/excel_export/group/' + id;
            this.downloadQuery = null;
            this.openModal(this.$refs.downloadContactsModal);
        },
        deleteContactListFromModal(id) {
            this.closeContactListModal();
            this.doDeleteContactList(id);
        },
        doDeleteContactList(id) {
            this.loading = true;

            this.$axios.delete("/api/contact_lists/" + id).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Deleted",
                    message: "Group deleted",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }).finally(() => {
                this.contactListsUpdated = moment().unix();
            });
        },
        cancelDeleteContactList() {
            this.contactListToDelete = null
        },
        favUpdated() {
            this.contactListsUpdated = moment().unix()
        },
        clearPreSelection() {
            this.preSelectedRows = [];
            this.preSelectedRowIds = [];
            this.selectedRows = [];
        },
        openBulkOperationModal(mode) {
            // this.mode = mode;
            // this.bulkTriggered = true;
            this.bulkUpdated = moment().unix();
            this.$nextTick(() => {
                this.bulkModal = this.openModal(this.$refs.bulkOperationModal)
            })
        },
        contactBulk(selectedContactIds) {
            this.selectedContactIds = selectedContactIds;
            this.closeModal(this.bulkModal);
            this.contactBulkUpdated = moment().unix();
            this.$nextTick(() => {
                this.bulkModal = this.openModal(this.$refs.contactBulkOperationModal)
            })
        },
        onBulkSubmitted() {
            this.contactListsUpdated = moment().unix();
            this.clearPreSelection();
            this.closeModal(this.bulkModal);
        },
        downloadBulkContacts() {
            this.closeModal(this.bulkModal);
            this.exportPath = "/api/contacts/excel_export"
            this.downloadQuery = {
                filters: this.selectedContactIds,
            };
            this.openModal(this.$refs.downloadContactsModal);
        },
        updateSearchQuery() {
            this.pageChanged = true
        },
        onPageChange() {
            this.pageChanged = true;
        },
        createSharedWithUsersTitle(sharedWithUserIds, creatingUserId, creatingUserName) {
            let title = '';

            if (this.userInfo.id === creatingUserId) {
                title += 'Shared by you';
            } else {
                title += `Shared by ${creatingUserName}`;
            }

            if (sharedWithUserIds.includes(this.userInfo.id)) {
                title += ` with you`;
                if (sharedWithUserIds.length - 1 > 0) {
                    title += ` and ${this.pluralize(sharedWithUserIds.length - 1, 'other user')}`;
                }
            } else {
                title += ` with ${this.pluralize(sharedWithUserIds.length, 'user')}`;
            }

            return title;
        },
        createCleansingGroups() {
            this.$axios.get("/api/contact_lists/create_cleansing").then(
                response => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: response.data.groups !== undefined ? (`Created ${response.data.groups} new groups with ${response.data.contacts} contacts`) : "Server ran out of memory",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    this.onContactListUpdated();
                }
            )
        }
    }
}
